@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'bahnschrift';
    src: local('bahnschrift'), url(./fonts/BAHNSCHRIFT.TTF) format('ttf');
  }

  body{
    width: 100%; 
    height : 100%;
  }

