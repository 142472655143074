
  .hoover .addPicture {
    background-color: #d3d3d3;
    position: absolute;
    color: #fff;
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    opacity: 0;
   
  }
  .hoover:hover .addPicture {
    opacity: 0.8;
  }
  .hoover .text {
    
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    -ms-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    transform: translateY(30px);
    -webkit-transform: translateY(30px);
  }
  .hoover .ico {
    font-size: 2.5em;
    text-transform: uppercase;
    opacity: 0;
    transition-delay: 0.2s;
    transition-duration: 0.3s;
  }
  .hoover:hover .ico,
  .hoover:focus .ico {
    opacity: 1;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
  }