@keyframes moveTruck {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(270px);
  }
}

.truck-icon {
  animation: moveTruck 6s linear infinite;
}